import React, {useState, useEffect } from "react"
import InfoContext from "./InfoContext"

const InfoProvider = ({children}) => {
  const windowGlobal = typeof window !== "undefined" && window
  /**
   * Provider's States
   */
  const [cover, setCover] = useState("")
  const [exp, setExp] = useState([])
  const [edu, setEdu] = useState([])
  const [preferStores, setPreferStores] = useState([])
  const [info, setInfo] = useState({
    check: "",
    visa: 0,
    visaDesc: "",
    vapeKnowledge: "",
    weekend: "",
    longShift: "",
    attitude: "",
    guideline: "",
    ownTransport: "",
  })
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dob: "",
    LinkedIn: "",
    Facebook: "",
    Twitter: "",
    Website: "",
  })
  /**
   * Update {personalInfo} State
   * @param content
   * @param value
   */
  const updatePerson = (content, value) => {
    setPersonalInfo(prevState => ({
      ...prevState,
      [content]: value
    }))
  }
  /**
   * Update {exp} State
   */
  const updateExp = () => {
    setExp(JSON.parse(windowGlobal.localStorage.getItem("_exp")))
  }
  /**
   * Update {edu} State
   */
  const updateEdu = () => {
    setEdu(JSON.parse(windowGlobal.localStorage.getItem("_edu")))
  }
  /**
   * Update {preferStores}
   * @param val
   */
  const updatePreferStores = (val) => {
    setPreferStores(val)
  }
  /**
   * Update {info} State
   * @param content
   * @param value
   */
  const updateInfo = (content, value) => {
    setInfo(prevState => ({
      ...prevState,
      [content]: value
    }))
  }
  /**
   * Update {cover} State
   * @param value
   */
  const updateCover = (value) => {
    setCover(value)
  }
  /**
   * useEffect
   */
  useEffect(() => {
    (async () => {
      const exp = windowGlobal.localStorage.getItem("_exp")
      const edu = windowGlobal.localStorage.getItem("_edu")
      const id = windowGlobal.localStorage.getItem("_id")
      exp ? setExp(exp) : await windowGlobal.localStorage.setItem("_exp", JSON.stringify([]))
      edu ? setEdu(edu) : await windowGlobal.localStorage.setItem("_edu", JSON.stringify([]))
      if(!id) {
        const _id = Math.random().toString(36).substr(2, 9)
        const __id = "_" + Math.random().toString(36).substr(2, 9) + _id
        windowGlobal.localStorage.setItem("_id", __id)
      }
    })()
  }, [windowGlobal.localStorage])

  return (
    <InfoContext.Provider value={{
      exp,
      edu,
      info,
      personalInfo,
      cover,
      preferStores,
      updatePreferStores,
      updateExp,
      updateEdu,
      updateInfo,
      updatePerson,
      updateCover,
    }}>
      {children}
    </InfoContext.Provider>
  )
}

export default InfoProvider