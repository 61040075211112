import * as React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Title } from "react-admin"
import { Helmet } from "react-helmet"

export default () => (
  <>
    <Helmet title="Admin - Dashboard" defer={false}/>
    <Card>
      <Title title="Welcome to Shosha HR" />
      <CardContent>Lorem ipsum sic dolor amet...</CardContent>
    </Card>
  </>
);