import React, {useContext} from "react";
import {Button, Divider, Form, Header, Message, Segment} from "semantic-ui-react";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {DropzoneArea} from "material-ui-dropzone";
import PersonalInfo from "./personalInfo";
import CoverLetter from "./coverLetter";
import FileContext from "../Context/FileContext";
import Education from "./education";
import Experience from "./experience";
import {Link} from "react-router-dom";
import Layout from "../Layout";

const Page1 = () => {
  const {file, updateFile} = useContext(FileContext);

  const useStyles = makeStyles(theme => createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210
    },
    root: {
      minHeight: 100
    },
    textContainer: {
      padding: "1rem"
    }
  }));
  const classes = useStyles();
  return (
    <Layout>
      <Form>
        {/*Personal Info*/}
        <PersonalInfo/>
        {/*Personal Experience*/}
        <Experience/>
        {/*Personal Education*/}
        <Education/>
        {/*Resume File*/}
        <Header as='h3' content='Resume'/>
        {
          file[0]
            ? (<Segment basic>
                <Message content="You have uploaded your CV!" icon={"file"}/>
                <Button content="Upload a different CV!" color={"black"} onClick={() => {
                  updateFile([]);
                }}/>
              </Segment>
            )
            :
            (<DropzoneArea
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                filesLimit={1}
                previewGridProps={{container: {spacing: 1, direction: 'row'}}}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Uploaded files"
                classes={{root: classes.root, textContainer: classes.textContainer}}
                onChange={(files => {
                  updateFile(files);
                })}
                clearOnUnmount={false}
              />
            )
        }

        <Divider/>
        {/*Cover Letter*/}
        <CoverLetter/>
        <Link to="/next">
          <Button color={"black"} content={"Next"} icon={"angle double right"} labelPosition={"right"}/>
        </Link>
      </Form>
    </Layout>
  );
};

export default Page1;