import React, { useContext, useState } from "react"
import { Button, Divider, Grid, Header, Icon, Input, Segment, TextArea } from "semantic-ui-react"
import InfoContext from "../Context/InfoContext"

const Education = () => {
  const {updateEdu } = useContext(InfoContext)
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState({
    institution: "",
    major: "",
    degree: "",
    location: "",
    description: ""
  })
  const handleChange = (e, { name, value }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSubmit = async () => {
    let edu = JSON.parse(window.localStorage.getItem("_edu"))
    edu.push(state)
    await window.localStorage.setItem("_edu", JSON.stringify(edu))
    await updateEdu()
    setIsOpen(false)
    setState({
      institution: "",
      major: "",
      degree: "",
      location: "",
      description: ""
    })
  }
  const display = () => {
    const info = JSON.parse(window.localStorage.getItem("_edu"))
    if (info) {
      return (
        <Segment.Group>
          {info.map((item, i) => {
            return (
              <Segment key={i}>
                <Header as='h2'>
                  <Icon name='university' />
                  <Header.Content>
                    {item.institution} - {item.major} - {item.location}
                    <Header.Subheader>{item.degree}</Header.Subheader>
                    <Header.Subheader>{item.description}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            )
          })}
        </Segment.Group>
      )
    }
  }
  return (
    <>
      <Header as='h3' content='Education' />
      <Button icon='plus' content='Add' color={"red"} basic onClick={() => {
        setIsOpen(true)
      }} />
      <Segment style={isOpen ? null : { display: "none" }} secondary>
        <Grid columns={"equal"}>
          <Grid.Row>
            <Grid.Column>
              <Input label="Institution" name="institution" value={state.institution} onChange={handleChange} fluid/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign={"middle"}>
              <Input label="Major" name="major" value={state.major} onChange={handleChange} fluid />
            </Grid.Column>
            <Grid.Column>
              <Input label="Degree" name="degree" value={state.degree} onChange={handleChange} fluid/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input label="Location (City)" name="location" value={state.location} onChange={handleChange} fluid/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TextArea label="Description" name="description" value={state.description} onChange={handleChange} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Button content="Save" onClick={handleSubmit} />
      </Segment>
      {display()}
      <Divider />
    </>
  )
}

export default Education