import React, { useState } from "react"
import FileContext from "./FileContext"

const FileProvider = ({ children }) => {
  const [file, setFile] = useState([])

  const updateFile = (_file) => {
    setFile(_file)
  }
  return (
    <FileContext.Provider value={{
      file,
      updateFile
    }}>
      {children}
    </FileContext.Provider>
  )
}

export default FileProvider