import { Segment } from "semantic-ui-react"
import React from "react"

const Footer = () => {
  return (
    <Segment.Group horizontal style={{margin: 0, backgroundColor: "#e3e3e3", borderRadius: 0, marginTop: "2rem", border: "unset"}}>
      <Segment textAlign={"center"}>©Copyright 2020 Shosha | Mason Online Limited. All rights reserved.</Segment>
    </Segment.Group>
  )
}
export default Footer