import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  RichTextField,
  EmailField,
  ShowButton,
  FunctionField
} from 'react-admin';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Message, Segment, Header, Button} from "semantic-ui-react";

const ExperienceComponent = ({source, record = {}}) => {
  if (record[source] === "[]") {
    return (
      <Segment basic>
        <Message negative content={"Not Listed"}/>
      </Segment>
    );
  } else {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record[source].map((item, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.company}</TableCell>
                  <TableCell>{item.location}</TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};
const EducationComponent = ({source, record = {}}) => {
  if (record[source] === "[]") {
    return (
      <Segment basic>
        <Message negative content={"Not Listed"}/>
      </Segment>
    );
  } else {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Degree</TableCell>
              <TableCell>Major</TableCell>
              <TableCell>Institution</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record[source].map((item, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{item.degree}</TableCell>
                  <TableCell>{item.major}</TableCell>
                  <TableCell>{item.institution}</TableCell>
                  <TableCell>{item.location}</TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};
const PreferStoresComponent = ({source, record = {}}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));
  const classes = useStyles();
  const stores = record[source];
  if (stores.length > 0) {
    return (
      <>
        <Header as="h5" content={"Prefer Stores"} style={{marginBottom: 0}}/>
        <ul className={classes.root}>
          {stores.map((item, i) => {
            return (
              <li key={i}>
                <Chip
                  label={item}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </ul>
      </>
    );
  } else {
    return (
      <Message content={"No prefer stores"}/>
    );
  }
};
const CVComponent = ({source, record = {}}) => {
  if (record[source] === "no CV") {
    return (
      <>
        <Header as={"h4"} content="CV"/>
        <p>No CV</p>
      </>
    );
  } else {
    return (
      <Button as={'a'} href={record[source]} content="View CV"/>
    );
  }
};

export const CareerList = (props) => (
  <List {...props} title="All Applications" sort={{field: 'date', order: 'ASC'}}>
    <Datagrid>
      <FunctionField label="Name"
                     render={record => `${record.PersonalInfo.firstName} ${record.PersonalInfo.lastName}`}/>
      <EmailField source="PersonalInfo.email" label="Email"/>
      <ShowButton/>
    </Datagrid>
  </List>
);

export const CareerShow = ({hasShow, hasEdit, hasCreate, hasList, ...props}) => (
  <Show {...props} title={<FunctionField label="Name"
                                         render={record => `${record.PersonalInfo.firstName} ${record.PersonalInfo.lastName}`}/>}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Personal Info">
        <FunctionField label="Name"
                       render={record => `${record.PersonalInfo.firstName} ${record.PersonalInfo.lastName}`}/>
        <EmailField source="PersonalInfo.email" label="Email"/>
        <TextField source="PersonalInfo.phoneNumber" label="Phone Number"/>
        <PreferStoresComponent source="PreferStores"/>
        <TextField source="PersonalInfo.Website" label="Website"/>
        <TextField source="PersonalInfo.LinkedIn" label="LinkedIn"/>
        <TextField source="PersonalInfo.Facebook" label="Facebook"/>
        <TextField source="PersonalInfo.Twitter" label="Twitter"/>
      </Tab>
      <Tab label="Resumé" path="resume">
        <CVComponent source="CV"/>
        <RichTextField source="CoverLetter" label='Cover Letter'/>
      </Tab>
      <Tab label="Experience">
        <ExperienceComponent source="Experience"/>
      </Tab>
      <Tab label="Education">
        <EducationComponent source="Education"/>
      </Tab>
    </TabbedShowLayout>
  </Show>
);