import axios from "axios";

export const firebaseConfig = {
    apiKey: "AIzaSyDAt7l27xk-GGz1I6OoUQGXV0FJEeVVTKc",
    authDomain: "shosha-careers.firebaseapp.com",
    databaseURL: "https://shosha-careers.firebaseio.com",
    projectId: "shosha-careers",
    storageBucket: "shosha-careers.appspot.com",
    messagingSenderId: "832598577592",
    appId: "1:832598577592:web:213b12c5100e6cb5de3f4d",
    measurementId: "G-6GM46LB5CV"
};

export const instance = axios.create({
    baseURL: "https://api.batteryvape.com",
})
