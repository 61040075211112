import React, {useContext, useState} from "react";
import { Divider, Form, Header } from "semantic-ui-react"
import InfoContext from "../Context/InfoContext"
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import StoreField from "./storeField";

const PersonalInfo = () => {
  const {personalInfo, updatePerson} = useContext(InfoContext)
  const [err, setErr] = useState(false)

  const handleChange = (e, { content, value }) => {
    if(content === "email") {
      if(!/\S+@\S+\.\S+/.test(value)) {
        setErr(true)
      } else {
        setErr(false)
        updatePerson(content, value)
      }
    } else {
      updatePerson(content, value)
    }
  }

  return (
    <>
      <Header as='h3'>Personal Information</Header>
      <Form.Group widths={"equal"}>
        <Form.Input label='First Name' defaultValue={personalInfo ? personalInfo.firstName : ""} placeholder='First Name' required onChange={handleChange} content="firstName" />
        <Form.Input label='Last Name' defaultValue={personalInfo ? personalInfo.lastName: ""} placeholder='Last Name' required onChange={handleChange} content="lastName" />
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Input label='Email' defaultValue={personalInfo ? personalInfo.email: ""} placeholder='Email' required onChange={handleChange} content="email" error={ err ? err : null}/>
        <Form.Input label='Phone Number' defaultValue={personalInfo ? personalInfo.phoneNumber: ""} placeholder='Phone Number' required onChange={handleChange} content="phoneNumber" />
      </Form.Group>
      <Form.Group widths={"equal"}>
        <SemanticDatepicker label='Date of Birth' value={personalInfo ? personalInfo.dob: null} allowOnlyNumbers={true} required format="DD-MM-YYYY" content="dob" onChange={handleChange} />
        <StoreField />
      </Form.Group>
      <Divider />
      <Header as='h3' content='On The Web' />
      <Form.Group widths={"equal"}>
        <Form.Input label='LinkedIn' placeholder='LinkedIn' onChange={handleChange} defaultValue={personalInfo ? personalInfo.LinkedIn : ""} content="LinkedIn" />
        <Form.Input label='Facebook' placeholder='Facebook' onChange={handleChange} defaultValue={personalInfo ? personalInfo.Facebook : ""} content="Facebook" />
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Input label='Twitter' placeholder='Twitter' onChange={handleChange} defaultValue={personalInfo ? personalInfo.Twitter : ""} content="Twitter" />
        <Form.Input label='Website' placeholder='Website' onChange={handleChange} defaultValue={personalInfo ? personalInfo.Website : ""} content="Website" />
      </Form.Group>
      <Divider />
    </>)
}
export default PersonalInfo