import React from "react";
import {Admin, Resource} from "react-admin"
import firebaseDataProvider from 'ra-data-firebase-client'
import {FirebaseAuthProvider} from 'react-admin-firebase';
import * as firebase from 'firebase/app';
import {firebaseConfig} from "../config/firebase";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {CareerList, CareerShow} from "./career";
import {Helmet} from "react-helmet";
import Dashboard from "./dashboard"
import StockXLSX from "./stockXLSX";

const dataProvider = firebaseDataProvider(firebase)
const authProvider = FirebaseAuthProvider(firebaseConfig, {})

const AdminPage = () => {
  return (
    <Admin dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Admin</title>
      </Helmet>
      <Resource name="career" list={CareerList} show={CareerShow} icon={AssignmentIndIcon}/>
      <Resource name="XLSX" list={StockXLSX} />
    </Admin>
  )
}

export default AdminPage