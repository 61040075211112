import { Divider, Header, TextArea } from "semantic-ui-react"
import React, { useContext } from "react"
import InfoContext from "../Context/InfoContext"

const CoverLetter = () => {
  const {cover, updateCover} = useContext(InfoContext)

  const handleChange = (e, {value}) => {
    updateCover(value)
  }

  return (
    <>
      <Header as='h3' content='Message to Hiring Manager' />
      <TextArea placeholder='Tell us more' style={{ minHeight: 100 }} value={cover} onChange={handleChange} />
      <Divider />
    </>
  )
}
export default CoverLetter