import React, { useContext, useEffect, useRef, useState } from "react"
import { Button, Dimmer, Header, Loader, Modal, Popup } from "semantic-ui-react"
import * as firebase from "firebase/app";
import moment from "moment"
import FileContext from "../Context/FileContext"
import InfoContext from "../Context/InfoContext"
import {instance} from "../../config/firebase";

const SubmitButton = () => {
  const { file } = useContext(FileContext)
  const { info, personalInfo, cover, exp, edu, preferStores } = useContext(InfoContext)
  const [id, setID] = useState("")
  const [open, setOpen] = useState(false)
  const [loaderActive, setLoaderActive] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [error, setError] = useState("")
  const contextRef = useRef()


  useEffect(() => {
    const _id = window.localStorage.getItem("_id")
    if (_id) {
      setID(_id)
    }
  }, [])

  const uploadRTD = (_url) => {
    console.log("Uploading....")
    firebase.database().ref(`career/${id}`).set({
      id: id,
      Experience: exp,
      Education: edu,
      Info: info,
      PersonalInfo: personalInfo,
      PreferStores: preferStores,
      CoverLetter: cover,
      CV: _url ? _url : "no CV",
      date: moment().format("DD-MMM-YYYY HH:mm:ss")
    }).then(() => {
      instance.post('/send', {
        mailTo: personalInfo.email,
        name: `${personalInfo.firstName} ${personalInfo.lastName}`
      }).then(() => {
        setLoaderActive(false)
        setOpen(true)
        console.log("Email Sent!!!")
      })
    }).then(() => {
      window.localStorage.removeItem("_exp")
      window.localStorage.removeItem("_edu")
      window.localStorage.removeItem("_id")
    }).catch(err => {
      setError(err.message)
      setPopupOpen(true)
      window.scrollTo(0, 0)
    })
  }

  const sendToFirebase = () => {
    if (file.length > 0) {
      const fileExtension = file[0].name.split(".")
      const uploadRef = firebase.storage().ref("/CV").child(`/${id}.${fileExtension[1]}`)
      uploadRef.put(file[0])
        .then(() => {
          uploadRef.getDownloadURL().then(url => {
            uploadRTD(url)
          })
        })
        .catch(err => {
          console.log(err.message)
        })
    } else {
      uploadRTD()
    }
  }

  const handleSubmit = () => {
    if (personalInfo.firstName && personalInfo.lastName && personalInfo.email) {
      setLoaderActive(true)
      sendToFirebase()
    } else {
      setError("Please fill all the required fields")
      setPopupOpen(true)
      window.scrollTo(0, 0)
    }
  }
  return (
    <>
      <Dimmer active={loaderActive} page>
        <Loader content="Submitting...." size={"massive"} />
      </Dimmer>
      <Button content={"Submit"} primary onClick={handleSubmit} icon={"paper plane"} />
      <Popup
        context={contextRef}
        content={error}
        open={popupOpen}
        position={"top center"}
        style={{
          maxWidth: "100%",
          width: "100%",
          textAlign: "center",
          fontSize: "1.2rem"
        }}
        onClose={() => {
          setPopupOpen(false)
        }}
      />
      <Modal
        open={open}
      >
        <Modal.Header>Successfully Applied</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>Thank you for applying to Shosha</Header>
            <p>
              You will receive an email from us shortly.
            </p>
            <p>You may close this tab now.</p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  )
}
export default SubmitButton