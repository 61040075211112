import React, {useContext, useEffect, useState} from "react";
import {Form, Dropdown} from "semantic-ui-react";
import InfoContext from "../Context/InfoContext";
import {instance} from "../../config/firebase";

const StoreField = () => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState([])
  const {updatePreferStores} = useContext(InfoContext)

  useEffect(() => {
    instance.get("/outlets")
      .then(res => {
        let storeArr = options
        res.data.map(item => {
          if(item.name !== "Warehouse" && item.name !== "Complaint") {
            storeArr.push({
              key: item.name,
              text: item.name,
              value: item.name
            });
          }
        })
        setOptions(storeArr)
      })
  },[])

  return (
    <Form.Field>
      <label>Your preferred stores!</label>
      <Dropdown
        fluid
        placeholder={"Select stores"}
        multiple
        selection
        options={options ? options : setLoading(true)}
        loading={loading}
        search
        value={searchValue}
        onChange={(e, {value}) => {
          setSearchValue(value)
          updatePreferStores(value)
        }}
      />
    </Form.Field>

  )
}

export default StoreField
