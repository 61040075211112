import React from "react"
import { Image, Segment } from "semantic-ui-react"

const Header = ({children}) => {
  return (
    <>
      <Segment textAlign={"center"} >
        <Image src='https://www.shosha.co.nz/media/logo/stores/2/logo_03.png' centered/>
      </Segment>
      {children}
    </>
  )
}

export default Header