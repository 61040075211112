import React, { useContext, useState } from "react"
import { Button, Divider, Grid, Header, Icon, Input, Segment, TextArea } from "semantic-ui-react"
import InfoContext from "../Context/InfoContext"

const Experience = () => {
  const {updateExp } = useContext(InfoContext)
  const windowGlobal = typeof window !== "undefined" ? window : null
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState({
    title: "",
    company: "",
    location: "",
    description: ""
  })

  const handleChange = (e, { name, value }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSubmit = async () => {
    let exp = JSON.parse(windowGlobal.localStorage.getItem("_exp"))
    exp.push(state)
    await window.localStorage.setItem("_exp", JSON.stringify(exp))
    await updateExp()
    setIsOpen(false)
  }
  const display = () => {
    const info = JSON.parse(windowGlobal.localStorage.getItem("_exp"))
    if (info) {
      return (
        <Segment.Group>
          {info.map((item, i) => {
            return (
              <Segment key={i}>
                <Header as='h2'>
                  <Icon name='building' />
                  <Header.Content>
                    {item.title}
                    <Header.Subheader>{item.description}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            )
          })}
        </Segment.Group>
      )
    }
  }
  return (
    <>
      <Header as='h3' content='Experience' />
      <Button icon='plus' content='Add' color={"red"} basic onClick={() => {
        setIsOpen(true)
      }} />
      <Segment style={isOpen ? null : { display: "none" }} secondary>
        <Grid columns={"equal"}>
          <Grid.Row>
            <Grid.Column>
              <Input label="Title" name="title" value={state.title} onChange={handleChange} fluid/>
            </Grid.Column>
            <Grid.Column verticalAlign={"middle"}>
              <Input label="Company" name="company" value={state.company} onChange={handleChange} fluid />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input label="Location (City)" name="location" value={state.location} onChange={handleChange} fluid/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TextArea label="Description" name="description" value={state.description} onChange={handleChange} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Button content="Save" onClick={handleSubmit} />
      </Segment>
      {display()}
      <Divider />
    </>
  )
}

export default Experience