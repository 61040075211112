import React, {useContext} from "react";
import {Button, Header, TextArea, Form, Divider} from "semantic-ui-react";
import InfoContext from "../Context/InfoContext";
import {Link} from "react-router-dom";
import SubmitButton from "../firebase/SubmitButton";
import Layout from "../Layout";

const Page2 = () => {
  const {info, updateInfo} = useContext(InfoContext);

  const options = [
    {
      key: "New Zealand/Australian Citizen",
      text: "New Zealand/Australian Citizen",
      value: "New Zealand/Australian Citizen"
    },
    {
      key: "New Zealand Permanent Resident",
      text: "New Zealand Permanent Resident",
      value: "New Zealand Permanent Resident"
    },
    {
      key: "Valid New Zealand Work Visa (If selected, please specify expiry date below)",
      text: "Valid New Zealand Work Visa (If selected, please specify expiry date below)",
      value: "Valid New Zealand Work Visa (If selected, please specify expiry date below)"
    },
    {
      key: "Valid New Zealand Student Visa (If selected, please specify expiry date below)",
      text: "Valid New Zealand Student Visa (If selected, please specify expiry date below)",
      value: "Valid New Zealand Student Visa (If selected, please specify expiry date below)"
    },
    {key: "Sponsorship in progress", text: "Sponsorship in progress", value: "Sponsorship in progress"},
    {
      key: "Not currently entitled to work in New Zealand",
      text: "Not currently entitled to work in New Zealand",
      value: "Not currently entitled to work in New Zealand"
    }
  ];

  const handleChange = (e, {content, value}) => {
    updateInfo(content, value);
  };

  return (
    <Layout>
      <Form>
        <Header as="h3" content="Preliminary questions"/>
        <Header as="h4" content="Are you 18 years or older?"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Yes'
            name='checkboxRadioGroup'
            value='Yes'
            checked={info.check === "Yes"}
            onChange={handleChange}
            content="check"
          />
          <Form.Checkbox
            radio
            label='No'
            name='checkboxRadioGroup'
            value='No'
            checked={info.check === "No"}
            onChange={handleChange}
            content="check"
          />
        </Form.Group>
        {/*Visa Condition*/}
        <Header as="h4"
                content="What is your entitlement to work in New Zealand? If you are invited for an interview, you will be required to bring a copy of your identification and Right to Work document."/>
        <Form.Dropdown
          onChange={handleChange}
          fluid
          options={options}
          placeholder='Choose an option'
          selection
          value={info.visa}
          content={"visa"}
        />
        <Form.Field
          style={
            info.visa === "Valid New Zealand Student Visa (If selected, please specify expiry date below)" ||
            info.visa === "Valid New Zealand Work Visa (If selected, please specify expiry date below)"
              ? null
              : {display: "none"}}>
          <label style={{fontSize: "1.07142857rem"}} className="ui header">If your answer above required specification,
            please
            elaborate.</label>
          <TextArea style={{minHeight: 100}} required={info.visa === 3 || info.visa === 4}
                    onChange={handleChange} content="visaDesc" value={info.visaDesc}/>
        </Form.Field>
        {/*Vape Knowledge*/}
        <Header as="h4" content="What is your vape knowledge?"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Zero'
            name='checkboxRadioGroup'
            value='Zero'
            checked={info.vapeKnowledge === "Zero"}
            onChange={handleChange}
            content="vapeKnowledge"
          />
          <Form.Checkbox
            radio
            label='Beginner'
            name='checkboxRadioGroup'
            value='Beginner'
            checked={info.vapeKnowledge === "Beginner"}
            onChange={handleChange}
            content="vapeKnowledge"
          />
          <Form.Checkbox
            radio
            label='Intermediate'
            name='checkboxRadioGroup'
            value='Intermediate'
            checked={info.vapeKnowledge === "Intermediate"}
            onChange={handleChange}
            content="vapeKnowledge"
          />
          <Form.Checkbox
            radio
            label='Advanced'
            name='checkboxRadioGroup'
            value='Advanced'
            checked={info.vapeKnowledge === "Advanced"}
            onChange={handleChange}
            content="vapeKnowledge"
          />
        </Form.Group>
        {/*Weekend*/}
        <Header as="h4" content="Can you work on weekend?"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Yes'
            name='checkboxRadioGroup'
            value='Yes'
            checked={info.weekend === "Yes"}
            onChange={handleChange}
            content="weekend"
          />
          <Form.Checkbox
            radio
            label='No'
            name='checkboxRadioGroup'
            value='No'
            checked={info.weekend === "No"}
            onChange={handleChange}
            content="weekend"
          />
        </Form.Group>
        {/*Long Shift*/}
        <Header as="h4" content="Can you work long shift?"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Yes'
            name='checkboxRadioGroup'
            value='Yes'
            checked={info.longShift === "Yes"}
            onChange={handleChange}
            content="longShift"
          />
          <Form.Checkbox
            radio
            label='No'
            name='checkboxRadioGroup'
            value='No'
            checked={info.longShift === "No"}
            onChange={handleChange}
            content="longShift"
          />
        </Form.Group>
        {/*Will-Do Attitude*/}
        <Header as="h4" content="Will-Do Attitude"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Yes'
            name='checkboxRadioGroup'
            value='Yes'
            checked={info.attitude === "Yes"}
            onChange={handleChange}
            content="attitude"
          />
          <Form.Checkbox
            radio
            label='No'
            name='checkboxRadioGroup'
            value='No'
            checked={info.attitude === "No"}
            onChange={handleChange}
            content="attitude"
          />
        </Form.Group>
        {/*Experience on working with guideline*/}
        <Header as="h4" content="Experience on working with guideline"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Yes'
            name='checkboxRadioGroup'
            value='Yes'
            checked={info.guideline === "Yes"}
            onChange={handleChange}
            content="guideline"
          />
          <Form.Checkbox
            radio
            label='No'
            name='checkboxRadioGroup'
            value='No'
            checked={info.guideline === "No"}
            onChange={handleChange}
            content="guideline"
          />
        </Form.Group>
        {/*Own Transport*/}
        <Header as="h4" content="Do you have your own transport?"/>
        <Form.Group unstackable>
          <Form.Checkbox
            radio
            label='Yes'
            name='checkboxRadioGroup'
            value='Yes'
            checked={info.ownTransport === "Yes"}
            onChange={handleChange}
            content="ownTransport"
          />
          <Form.Checkbox
            radio
            label='No'
            name='checkboxRadioGroup'
            value='No'
            checked={info.ownTransport === "No"}
            onChange={handleChange}
            content="ownTransport"
          />
        </Form.Group>
        <Divider/>
        <Link to="/">
          <Button content={"Back"} color={"black"} icon={"angle double left"} labelPosition={"left"} />
        </Link>
        <SubmitButton/>
      </Form>
    </Layout>
  );
};

export default Page2;