import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Page1 from "./components/Form/page1";
import Page2 from "./components/Form/page2";
import AdminPage from "./admin";
import InfoProvider from "./components/Context/InfoProvider";
import FileProvider from "./components/Context/FileProvider";

function App() {
  return (
    <InfoProvider>
      <FileProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Page1}/>
            <Route exact path="/next" component={Page2}/>
            <Route exact path="/admin" component={AdminPage}/>
          </Switch>
        </Router>
      </FileProvider>
    </InfoProvider>
  );
}

export default App;
