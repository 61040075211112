import React from "react";
import {Helmet} from "react-helmet";
import "semantic-ui-css/semantic.min.css";
import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import {firebaseConfig} from "../../config/firebase";
import "semantic-ui-css/semantic.min.css";
import Footer from "../Footer";
import Header from "../Header";
import {Container} from "semantic-ui-react";

firebase.initializeApp(firebaseConfig);

const Layout = ({children}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Shosha Careers</title>
      </Helmet>
      <Header>
        <Container>
          {children}
        </Container>
      </Header>
      <Footer/>
    </>
  );
};

export default Layout;