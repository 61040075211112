import React, { useState } from "react"
import { Input, Button, Container, Header, Grid } from "semantic-ui-react"
import * as XLSX from "xlsx"
import axios from "axios"
import * as firebase from "firebase"

const StockXLSX = () => {
  const [stockData, setStockData] = useState(null)
  const [name, setName] = useState("")
  const [f, setFile] = useState(null)
  const reader = new FileReader()

  const stockTakeUpload = (e, data) => {
    e.preventDefault()
    let files = e.target.files, f = files[0]
    reader.onload = function(e) {
      let data = e.target.result
      let wb = XLSX.read(data, { type: "binary" })
      setStockData(wb)
    }
    reader.readAsBinaryString(f)
  }
  const vendUpload = (e, data) => {
    e.preventDefault()
    let files = e.target.files, f = files[0]
    setFile(f)
    reader.onload = function(e) {
      let data = e.target.result
      let wb = XLSX.read(data, { type: "binary" })
    }
    reader.readAsBinaryString(f)
  }
  const downloadFile = () => {
    reader.onload = async e => {
      let data = e.target.result
      let wb = XLSX.read(data, { type: "binary" })
      let first_sheet_name = wb.SheetNames[0]
      let ws = wb.Sheets[first_sheet_name]
      let wsJson = XLSX.utils.sheet_to_json(ws, { header: 1 })

      await stockData.SheetNames.map((sheet, i) => {
        const stockJson = XLSX.utils.sheet_to_json(stockData.Sheets[sheet], { header: 1 })
        //Compare name
        stockJson.map((data, i) => {
          for (let j = 2; j <= wsJson.length; j++) {
            let productSKU = ws[`U${j}`].v
            if (productSKU) {
              if (String(data[0]) === productSKU) {
                ws[`V${j}`].v = data[2]
              }
            }
          }
        })
      })
      XLSX.writeFile(wb, `${name}.xlsx`)
    }
    reader.readAsBinaryString(f)
  }

  return (
    <Container>
      <Grid columns={1}>
        <Grid.Column>
          <Header as={"h2"}>File Name</Header>
          <Input onChange={(e, {value}) => setName(value)} />
        </Grid.Column>
        <Grid.Column>
          <Header as={"h2"}>Store Stocktake</Header>
          <Input type="file" onChange={stockTakeUpload} />
        </Grid.Column>
        <Grid.Column verticalAlign={"middle"}>
          <Header as={"h2"}>Vend File</Header>
          <Input type="file" onChange={vendUpload} />
        </Grid.Column>
        <Grid.Column>
          <Button onClick={downloadFile}>Download</Button>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default StockXLSX
