import React from "react"

const InfoContext = React.createContext({
  exp: [],
  edu: [],
  info: {},
  personalInfo: {},
  cover: "",
  preferStores: [],
  updatePreferStores: () => {},
  updateExp: () => {},
  updateEdu: () => {},
  updateInfo: () => {},
  updatePerson: () => {},
  updateCover: () => {}
})

export default InfoContext